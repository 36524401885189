import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import SuperTokens, { SuperTokensWrapper } from "supertokens-auth-react";
import { getSuperTokensRoutesForReactRouterDom } from "supertokens-auth-react/ui";
import { SessionAuth } from "supertokens-auth-react/recipe/session";
import { PreBuiltUIList, SuperTokensConfig, ComponentWrapper } from "./config";
import "./App.css";

const LandingPage = lazy(() => import("./Pages/Landing/Landing"));
const OnboardingPage = lazy(() => import("./Pages/Onboarding/Onboarding"));
const EditorWrapper = lazy(() => import("./Pages/Editor/EditorWrapper"));
const Reroute = lazy(() => import("./Pages/Redirect/routeToLanding"));
const Profile = lazy(() => import("./Pages/Profile/Profile"));
const AdminDashboard = lazy(() => import("./Pages/AdminDashboard/AdminDashboard"));
//const Home = lazy(() => import("./Home"));

SuperTokens.init(SuperTokensConfig);

function App() {
    return (
        <SuperTokensWrapper>
            <ComponentWrapper>
                <Router>
                    <Suspense fallback={<div>Loading...</div>}>
                        <Routes>
                            {/* This shows the login UI on "/auth" route */}
                            {getSuperTokensRoutesForReactRouterDom(require("react-router-dom"), PreBuiltUIList)}

                            <Route path="/" element={<Reroute />} />
                            <Route path="/landing" element={<LandingPage />} />
                            <Route 
                                path="/onboarding" 
                                element={
                                    <SessionAuth>
                                        <OnboardingPage />
                                    </SessionAuth>
                                }
                            />
                            <Route 
                                path="/editor" 
                                element={
                                    <SessionAuth>
                                        <EditorWrapper />
                                    </SessionAuth>
                                } 
                            />
                            <Route 
                                path="/profile" 
                                element={
                                    <SessionAuth>
                                        <Profile />
                                    </SessionAuth>
                                }
                            />
                            <Route 
                                path="/admin" 
                                element={
                                    <SessionAuth>
                                        <AdminDashboard />
                                    </SessionAuth>
                                }
                            />
                            <Route
                                path="*"
                                element={<div>404 Not Found</div>}
                            />
                        </Routes>
                    </Suspense>
                </Router>
            </ComponentWrapper>
        </SuperTokensWrapper>
    );
}

export default App;