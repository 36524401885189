import ThirdParty, { Google, Github, Apple } from "supertokens-auth-react/recipe/thirdparty";
import { ThirdPartyPreBuiltUI } from "supertokens-auth-react/recipe/thirdparty/prebuiltui";
import EmailPassword from "supertokens-auth-react/recipe/emailpassword";
import { EmailPasswordPreBuiltUI } from "supertokens-auth-react/recipe/emailpassword/prebuiltui";
import Session from "supertokens-auth-react/recipe/session";

export function getApiDomain() {
    const apiPort = process.env.REACT_APP_API_PORT || 3001;
    const apiUrl = process.env.REACT_APP_API_URL || `http://localhost:${apiPort}`;
    return apiUrl;
}

export function getWebsiteDomain() {
    const websitePort = process.env.REACT_APP_WEBSITE_PORT || 3000;
    const websiteUrl = process.env.REACT_APP_WEBSITE_URL || `http://localhost:${websitePort}`;
    return websiteUrl;
}

export const SuperTokensConfig = {
    appInfo: {
        appName: "CodeWithJessAI",
        apiDomain: getApiDomain(),
        websiteDomain: getWebsiteDomain(),
        apiBasePath: process.env.REACT_APP_API_BASE_PATH || "/api/auth",
    },
    style: `
        [data-supertokens~=container] {
            --palette-background: 18, 18, 18; /* Dark background */
            --palette-inputBackground: 30, 30, 30; /* Slightly lighter for input fields */
            --palette-inputBorder: 60, 60, 60; /* Border color for inputs */
            --palette-textTitle: 255, 255, 255; /* White text for titles */
            --palette-textLabel: 200, 200, 200; /* Light grey for labels */
            --palette-secondaryText: 255, 255, 255;
            --palette-error: 255, 69, 58; /* Bright red for errors */
            --palette-textInput: 169, 169, 169; /* Grey text for input fields */
            --palette-textLink: 100, 149, 237; /* Cornflower blue for links */
            --palette-buttonPrimaryBackground: 100, 149, 237; /* Cornflower blue for primary buttons */
            --palette-buttonPrimaryText: 255, 255, 255; /* White text for primary buttons */
            --palette-buttonSecondaryBackground: 60, 60, 60; /* Dark grey for secondary buttons */
            --palette-buttonSecondaryText: 255, 255, 255; /* White text for secondary buttons */
            --palette-buttonHoverBackground: 164, 40, 129; /* Steel blue for button hover */
            --pallete-primary: 100, 149, 237; /* Cornflower blue for primary buttons */
        }

        [data-supertokens~=input] {
            background-color: rgb(var(--palette-inputBackground));
            border: 1px solid rgb(var(--palette-inputBorder));
            color: rgb(var(--palette-textInput));
        }

        [data-supertokens~=button] {
            background-color: rgb(var(--palette-buttonPrimaryBackground));
            color: rgb(var(--palette-buttonPrimaryText));
        }

        [data-supertokens~=buttonSecondary] {
            background-color: rgb(var(--palette-buttonSecondaryBackground));
            color: rgb(var(--palette-buttonPrimaryText));
        }

        [data-supertokens~=button]:hover {
            background-color: rgb(var(--palette-buttonHoverBackground));
        }

        [data-supertokens~=textLink] {
            color: rgb(var(--palette-textLink));
        }

        [data-supertokens~=error] {
            color: rgb(var(--palette-error));
        }

        [data-supertokens~=title] {
            color: rgb(var(--palette-textTitle));
        }

        [data-supertokens~=label] {
            color: rgb(var(--palette-textLabel));
        }

        [data-supertokens~=primaryText] {
            color: rgb(var(--palette-textPrimary));
        }

        [data-supertokens~=authPage] [data-supertokens~=headerSubtitle] {
            display: none;
        }
    `,
    // recipeList contains all the modules that you want to
    // use from SuperTokens. See the full list here: https://supertokens.com/docs/guides
    recipeList: [
        EmailPassword.init(),
        ThirdParty.init({
            signInAndUpFeature: {
                providers: [/*Github.init(), */Google.init()/*, Apple.init()*/], // Disabled until OAuth2.0 setup is complete
            },
        }),
        Session.init({}),
    ],
};

export const recipeDetails = {
    docsLink: "https://supertokens.com/docs/thirdpartyemailpassword/introduction",
};

export const PreBuiltUIList = [ThirdPartyPreBuiltUI, EmailPasswordPreBuiltUI];

export const ComponentWrapper = (props: { children: JSX.Element }): JSX.Element => {
    return props.children;
};
